import { ListItemButton, ListItemIcon, ListItemText, Skeleton } from '@mui/material';
import { CustomTooltip, IBaseRight, IOptionType, NavBar, NavDrawer, SideMenuItem, lowercase } from '@zz2/zz2-ui';
import lodash from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { IUser } from '../../@types/model/auth/user/user';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import AuthThunks from '../../store/auth/thunk';
import GeneralActions from '../../store/general/actions';
import { VERSION } from '../../version';
import Routes from './Routes';
import UserSettings from './UserSettings';
import UserHelper from '../../service/helper/userHelper';
import { getUserSelectedDepartmentsLocalStorage, getUserSelectedDivisionsLocalStorage, setUserSelectedDepartmentsLocalStorage, setUserSelectedDivisionsLocalStorage } from '../../service/localStorageService';
import { useGetDepartments } from '../../hooks/query/masterData/departmentQueries';
import EnvHelper from '../../service/helper/envHelper';
import DataActions from '../../store/data/actions';
import { useGetDivisions } from '../../hooks/query/masterData/divisionQueries';
import FreshServiceTicketCreationDialog from '../freshService/FreshServiceTicketCreationDialog';
import FreshServiceActions from '../../store/freshService/actions';
import { RIGHT_GRANTS } from '../../appConstants';
import { useGetRights } from '../../hooks/query/rights/rightsQueries';

const iconsLocation = `${ASSET_BASE}/assets/icons`;

const NavFrame = () : React.ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const currentUser = useAppSelector<IUser | undefined>(x => x.auth.session?.user);
    const isNavDrawerOpen = useAppSelector(x => x.general.isNavDrawerOpen);
    const freshTicketServiceDescription = useAppSelector(x => x.freshService.supportTicketDescription);
    const isFreshServiceTicketDialogOpen = useAppSelector(x => x.freshService.isSupportTicketDialogOpen);

    const selectedDepartments : Array<IOptionType> = getUserSelectedDepartmentsLocalStorage();
    const selectedDivisions = getUserSelectedDivisionsLocalStorage();

    const [isUserSettingsDialogOpen, setIsUserSettingsDialogOpen] = useState<boolean>(false);

    const { isLoading: isLoadingRights, data: rights } = useGetRights();
    const { data: departments } = useGetDepartments();
    const { data: divisions } = useGetDivisions();
    
    /*================================================================================================================
     *                                              Effects
     * ==============================================================================================================*/

    useEffect(() => {
        loadData();
    }, [divisions, departments]);

    /*================================================================================================================
     *                                          Handler Methods - NavBar & Drawer
     * ==============================================================================================================*/

    const loadData = () : void => {
        if (currentUser?.divisionIds && currentUser.divisionIds.length > 0) {
            if (selectedDivisions.length < 1) return;

            dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisions.map(x => Number(x.value))));
        } else {
            setUserSelectedDivisionsLocalStorage([]);
            dispatch(DataActions.setSelectedUserDivisionIds(null));
        }

        if (currentUser?.departmentIds && currentUser.departmentIds.length > 0) {
            if (selectedDepartments.length < 1) return;

            dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartments.map(x => Number(x.value))));
        } else {
            setUserSelectedDepartmentsLocalStorage([]);
            dispatch(DataActions.setSelectedUserDepartmentIds(null));
        }

        if (currentUser && (!currentUser.divisionIds || !currentUser.departmentIds)) {
            setIsUserSettingsDialogOpen(true);
        }
    };    

    const signOut = () : void => {
        dispatch(AuthThunks.logout());
        dispatch(GeneralActions.setNavDrawer(false));
    };

    const openUserSettings = () : void => {
        setIsUserSettingsDialogOpen(true);
    };

    const closeUserSettings = () : void => {
        setIsUserSettingsDialogOpen(false);
    };

    const openDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(true));
    };

    const closeDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(false));
    };

    const openSupportTicketDialog = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
    };

    const closeFreshServiceTicketDialog = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(false));
    };

    const requestAccess = (right : IBaseRight) : void => {
        dispatch(FreshServiceActions.setSupportTicketDescription(`Requesting permission: ${right.name} - View.`));
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const divisionHeading = useMemo<string>(() => {
        return UserHelper.getUserDivisionHeading(currentUser, selectedDivisions, divisions);
    }, [selectedDivisions, isUserSettingsDialogOpen]);

    const departmentHeading = useMemo<string>(() => {
        return UserHelper.getUserDepartmentHeading(currentUser, selectedDivisions, selectedDepartments, departments);
    }, [selectedDivisions, selectedDepartments, isUserSettingsDialogOpen]);

    const userSettings = useMemo<React.ReactElement>(() => {
        if (currentUser) {
            return <UserSettings 
                isOpen={isUserSettingsDialogOpen}
                currentUser={currentUser}
                setIsUserDialogOpenCallback={(state : boolean) : void => setIsUserSettingsDialogOpen(state)}
                onClose={closeUserSettings}
                signOut={signOut}
            />;
        }

        return <div></div>;
    }, [isUserSettingsDialogOpen, currentUser]);

    const currentUserRights = useMemo(() => {
        return currentUser?.userRights.map(x => x.right);
    }, [currentUser]);

    const userCurrentRight = useMemo<IBaseRight | undefined>(() => {
        const urlSections = location.pathname.split('/'); 
        const firstPartition = urlSections.slice(0, -1).join('/');
        
        return currentUserRights?.filter(x => x.isActive)?.find(
            x => x.url === (urlSections[urlSections.length - 1].includes('Id') 
                ? firstPartition
                : location.pathname
            )
        );
    }, [currentUser, location.pathname]);

    const breadcrumbs = useMemo<Array<{ name : string; url : string }>>(() => {
        const breadcrumbList : Array<{ name : string; url : string }> = [];

        if (userCurrentRight) {
            let currentRight : IBaseRight | undefined = userCurrentRight;

            do {
                breadcrumbList.push({ name: currentRight.name, url: currentRight.url ?? '' });
                currentRight = currentUserRights?.find(x => x.id === currentRight?.breadcrumbParentId);
            } while (currentRight);
        }

        return breadcrumbList.reverse();
    }, [currentUser, location, currentUserRights, userCurrentRight]);

    /*================================================================================================================
     *                                                  Render
     * ==============================================================================================================*/

    const isParentOfCurrentPage = (parentId : number, rights ?: Array<IBaseRight>, pathname ?: string) : boolean => {
        const currentPageRight = rights?.find(x => x.url === pathname);
        const parentRight = rights?.find(x => x.id === parentId);

        if (currentPageRight?.parentId) {
            return (currentPageRight.parentId === parentId) || (!!parentRight?.parentId && isParentOfCurrentPage(parentRight.parentId, rights, pathname));
        }
        return false;
    };

    const renderListSection = (right : IBaseRight, children : Array<IBaseRight>, rights ?: Array<IBaseRight>, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x =>
            x.isActive
            && x.right.id === right.id
            && x.rightGrantLevel === RIGHT_GRANTS.View);

        return (
            <CustomTooltip
                title={!hasViewRight ? `Right: ${right.name}, level: View is required for access. \n\nClick to request permission.` : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}>
                <SideMenuItem
                    className={'pt10'}
                    paddingLeft={0}
                    icon={<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`}/> }
                    title={right.name}
                    key={'section_' + right.id}
                    boldTitle={isParentOfCurrentPage(right.id, rights, pathname)}
                    disabled={!hasViewRight}>
                    {children.map(x => renderListItem(x, false, pathname))}
                </SideMenuItem>
            </CustomTooltip>
        );
    };

    const renderListItem = (right : IBaseRight, hasIcon : boolean, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x =>
            x.isActive
            && x.right.id === right.id
            && x.rightGrantLevel === RIGHT_GRANTS.View);

        return (
            <CustomTooltip
                title={!hasViewRight ? `Right: ${right.name}, level: View is required for access. \n\nClick to request permission.` : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}>
                <Link key={`link ${right.url}`} to={right.url ?? ''} onClick={closeDrawer} style={{ pointerEvents: hasViewRight ? 'auto' : 'none', textDecoration: 'unset', color: 'unset' }} className={'tdus cb'}>
                    <ListItemButton 
                        key={right.guid} 
                        disabled={!hasViewRight}>
                        {
                            hasIcon ?
                                <ListItemIcon>
                                    {<img className={'h30 w30 mt8 mb8'} src={`${ASSET_BASE}/assets/icons/${lowercase(right.name).replace(/ /g, '_')}.svg`} />}
                                </ListItemIcon>
                                :
                                <div className={'h30 w30'} />
                        }
                        <ListItemText disableTypography className={`${pathname === right.url ? 'fw550' : ''} pl0`} inset primary={right.name} />
                    </ListItemButton>
                </Link>
            </CustomTooltip>
        );
    };

    const sections = useMemo<Array<JSX.Element>>(() => {
        if (isLoadingRights) {
            return [...Array(5)].map((_, index) => (
                <Skeleton 
                    key={`skeleton-${index}`} 
                    animation='wave' 
                    variant='rectangular' 
                    width={295} 
                    height={60} 
                    className={'mb10 br10'} 
                />
            ));
        }        

        return lodash.chain(rights)
            .filter(x => x.isOnNavDrawer && x.isActive && !x.parentId)
            .filter(x => !!x.sectionOrder)
            .sortBy(x => x.sectionOrder)
            .map((x) => {
                const userRights = currentUser?.userRights;
                const hasParentViewRight = userRights?.some(userRight =>
                    userRight.isActive
                    && userRight.right.id === x.id
                    && userRight.rightGrantLevel === RIGHT_GRANTS.View) ?? false;

                return {
                    hasParentViewRight,
                    section: x,
                    children: lodash.chain(rights)
                        .filter(y => y.isOnNavDrawer && y.isActive && y.parentId === x.id)
                        .filter(y => !!y.pageOrder)
                        .sortBy(y => y.pageOrder)
                        .map((child) => {
                            const hasChildViewRight = userRights?.some(userRight =>
                                userRight.isActive
                                && userRight.right.id === x.id
                                && userRight.rightGrantLevel === RIGHT_GRANTS.View) ?? false;

                            return {
                                hasChildViewRight,
                                child,
                            };
                        })
                        .orderBy(x => x.hasChildViewRight, 'desc')
                        .value(),
                };
            })
            .orderBy(x => x.hasParentViewRight, 'desc')
            .map((x) => {
                return x.section.isPage
                    ? renderListItem(x.section, true, location.pathname)
                    : renderListSection(x.section, x.children.map(x => x.child), currentUserRights, location.pathname);
            }).value();

    }, [currentUser, rights, location.pathname, isLoadingRights]);

    return (
        <div className={'fdc hfill'}>
            <NavBar
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                currentUser={currentUser}
                signOut={signOut}
                breadcrumbs={breadcrumbs}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                openUserSettings={openUserSettings}
                closeUserSettings={closeUserSettings}
                headings={[divisionHeading, departmentHeading]}
                disabled={false}       
                userSettingsDialog={userSettings}
                isLatestVersion={true}             
            />
            <div className={'fdr flx1 oyh'}>
                <NavDrawer
                    env={EnvHelper.getEnvName()} 
                    path={location.pathname}
                    currentUser={currentUser}
                    isOpen={isNavDrawerOpen}
                    isLoading={false}
                    sections={sections}
                    logOut={signOut}
                    closeDrawer={closeDrawer}
                    onHelpClick={openSupportTicketDialog}
                />
                <Routes />            
            </div>
            <FreshServiceTicketCreationDialog 
                isOpen={isFreshServiceTicketDialogOpen}
                onDismiss={closeFreshServiceTicketDialog}
                description={freshTicketServiceDescription}
            />
        </div>
    );
};

export default NavFrame;