import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../@types/redux';
import AuthThunk from '../../../store/auth/thunk';
import { OptionType } from '../../../@types/model/optionType';
import { IUser } from '../../../@types/model/auth/user/user';
import { Button } from '@mui/material';
import { getUserSelectedDepartmentsLocalStorage, getUserSelectedDivisionsLocalStorage, setUserSelectedDepartmentsLocalStorage, setUserSelectedDivisionsLocalStorage } from '../../../service/localStorageService';
import { ProjectDialog, PillButton, CustomTooltip, MuiAutocompleteSelect, IOptionType } from '@zz2/zz2-ui';
import DataActions from '../../../store/data/actions';
import { useGetDivisions } from '../../../hooks/query/masterData/divisionQueries';
import { useGetDepartments } from '../../../hooks/query/masterData/departmentQueries';

interface IDivDeptDialogProps {
    isOpen : boolean;
    onClose : () => void;
}

const DivDeptDialog = (props : IDivDeptDialogProps) : React.ReactElement => {
    const { isOpen, onClose } = props;
    const dispatch = useAppDispatch();
    const selectedDivisions = getUserSelectedDivisionsLocalStorage();
    const selectedDepartments = getUserSelectedDepartmentsLocalStorage();
    const currentUser : IUser | undefined = useAppSelector(x => x.auth.session?.user);

    const { isLoading: isLoadingDivisions, data: divisions } = useGetDivisions();
    const { isLoading: isLoadingDepartments, data: departments } = useGetDepartments();

    const [selectedDivisionOptions, setSelectedDivisionOptions] = useState<Array<IOptionType>>([]);
    const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState<Array<IOptionType>>([]);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (!isOpen) return;
        loadData();
    }, [isOpen]);

    const signOut = () : void => {
        dispatch(AuthThunk.logout());
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const loadData = () : void => {
        if (!currentUser) return;

        if (currentUser.divisionIds !== null && currentUser.divisionIds.length > 0 && selectedDivisions.length > 0) {
            const selectedUserDivisions : Array<IOptionType> = currentUser.userDivisions
                .filter(x => x.isActive && x.division.isActive && selectedDivisions.some(localStorageDiv => x.divisionId === localStorageDiv.value))
                .map(x => OptionType.fromDivision(x.division));
            setSelectedDivisionOptions(selectedUserDivisions);
        }
        if (currentUser.departmentIds !== null && currentUser.departmentIds.length > 0 && selectedDepartments.length > 0) {
            const selectedDepartmentIds = selectedDepartments.map(x => x.value);
            const selectedUserDepartments = currentUser.userDepartments
                .filter(x => x.isActive && x.department.isActive && selectedDepartmentIds.includes(x.departmentId))
                .map(x => OptionType.fromDepartment(x.department));
            setSelectedDepartmentOptions(selectedUserDepartments);
        }
    };    

    const onSubmit = () : void => {
        setUserSelectedDivisionsLocalStorage(selectedDivisionOptions);
        dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisionOptions.map(x => Number(x.value))));

        setUserSelectedDepartmentsLocalStorage(selectedDepartmentOptions);
        dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartmentOptions.map(x => Number(x.value))));

        onClose();
    };

    const onDivisionChange = (selectedOptions : Array<IOptionType>) : void => {
        if (selectedOptions.length > 0) {
            setSelectedDivisionOptions(selectedOptions);
            const newSelectedDepartments = departments?.filter(dept => dept.isActive
                && currentUser?.departmentIds?.some(deptId => dept.id === deptId)
                && selectedOptions.some(div => dept.subdivision?.divisionId === div.value))
                .map(OptionType.fromDepartment) ?? [];

            setSelectedDepartmentOptions(newSelectedDepartments);
        }
    };

    const onDepartmentChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDepartmentOptions(selectedOptions);
    };

    const onDivisionSelectAll = () : void => {
        if (divisionOptions.length !== selectedDivisionOptions.length) {
            onDivisionChange(divisionOptions);
        } else {
            const firstDivisionOption = selectedDivisionOptions.length > 0 ? selectedDivisionOptions[0] : null;

            // User must have at least one selected division at all times
            onDivisionChange(firstDivisionOption ? [firstDivisionOption] : []);
        }
    };

    const onDepartmentSelectAll = () : void => {
        if (departmentOptions.length !== selectedDepartmentOptions.length) {
            onDepartmentChange(departmentOptions);
        } else {
            const firstDepartmentOption : IOptionType | null = selectedDepartmentOptions.length > 0 ? selectedDepartmentOptions[0] : null;

            // User must have at least one selected department at all times
            onDepartmentChange(firstDepartmentOption ? [firstDepartmentOption] : []);
        }
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const isLoading = useMemo<boolean>(() => {
        return isLoadingDivisions || isLoadingDepartments;
    }, [isLoadingDivisions, isLoadingDepartments]);

    const divisionOptions = useMemo(() => {
        if (!divisions || !currentUser || !currentUser.divisionIds) return [];

        return divisions
            .filter(div => div.isActive && currentUser.divisionIds?.some(divId => div.id === divId))
            .map(OptionType.fromDivision);
    }, [divisions, currentUser]);

    const departmentOptions = useMemo(() => {
        if (!departments || !currentUser || !currentUser.departmentIds) return [];

        return departments
            .filter(dept => dept.isActive
                && currentUser.departmentIds?.some(deptId => dept.id === deptId)
                && selectedDivisionOptions.some(div => dept.subdivision?.divisionId === div.value))
            .map(OptionType.fromDepartment);
    }, [departments, currentUser, selectedDivisionOptions]);

    return (
        <ProjectDialog
            title={'Select Division(s) and Department(s)'}
            maxWidth={'sm'}
            fullWidth
            isOpen={isOpen}
            isLoadingCircular={isLoading}>
            <div className={'flx1 fdc aic jcc p20'}>
                <MuiAutocompleteSelect
                    isMulti
                    className={'w300 mt30'}
                    name={'divisions'}
                    label={'Divisions'}
                    options={divisionOptions}
                    value={selectedDivisionOptions}
                    onChange={onDivisionChange}
                    disableClearable
                />
                <Button size={'small'} onClick={onDivisionSelectAll} disabled={divisionOptions.length < 1}>
                    {(divisionOptions.length > 0 && divisionOptions.length === selectedDivisionOptions.length) ? 'Unselect All' : 'Select All'}
                </Button>
                <MuiAutocompleteSelect
                    isMulti
                    className={'w300 mt30 mxh200 oya'}
                    name={'departments'}
                    label={'Departments'}
                    options={departmentOptions}
                    value={selectedDepartmentOptions}
                    onChange={onDepartmentChange}
                    disableClearable
                />
                <Button size={'small'} onClick={onDepartmentSelectAll} disabled={departmentOptions.length < 1}>
                    {(departmentOptions.length > 0 && departmentOptions.length === selectedDepartmentOptions.length) ? 'Unselect All' : 'Select All'}
                </Button>
                <div className={'fdr mt20 aic jcc'}>
                    <CustomTooltip title={'Logout'}>
                        <PillButton color={'secondary'} className={'ml15 pl20 pr20 mr10 h35'} onClick={signOut} text={'Logout'}/>
                    </CustomTooltip>
                    <PillButton
                        color={'secondary'}
                        disabled={selectedDivisionOptions.length <= 0 || selectedDepartmentOptions.length <= 0}
                        className={'ml15 pl20 pr20 mr10 h35'}
                        onClick={onSubmit}
                        type='submit'
                        text='Submit'
                    />
                </div>
            </div>
        </ProjectDialog>
    );
};

export default DivDeptDialog;
